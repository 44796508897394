import React, { useEffect, useState } from "react";
import { useState as useHookState } from "@hookstate/core";
import { forceAwakensBaseState } from "src/stores/app";
import { DoraAuthConfig, DoraSmallClient } from "@amzn/sot-dora-components";
import i18n from "src/i18n";

export const useDoraClients = () => { 
    const forceAwakensState = useHookState(forceAwakensBaseState);
    const [isDoraClientVisible, setIsDoraClientVisible] = useState(false);
    const [isNonProd, setIsNonProd] = useState(false);
    const [deviceId, setDeviceId] = useState('');
    let stage = 'gamma';
    stage = stage === "test" ? "beta" : stage;
    const user = forceAwakensState.username.value!;
    const darkMode = forceAwakensState.darkMode.value;
    const doraAuthConfig: DoraAuthConfig = {
        stage: stage as 'beta' | 'gamma' | 'prod',
    };

    useEffect(() => { 
        if (deviceId) { 
            setIsDoraClientVisible(true);
        }

        if (stage !== 'prod') { 
            setIsNonProd(true);
        }
    }, [deviceId, darkMode, user, isNonProd]);

    // This is a workaround to adjust the width of the DoraClient to match the popover width when the DoraClient is visible
    // We have two approaches to adjust the width of the DoraClient to match the popover width
    // 1. Adjust the width of the popover to 500px when the DoraClient is visible
    // 2. If we could not find the className (if the name is changed by CloudScape build), then we will fallback to decrease the width of DoraClients to Match the popover width
    useEffect(() => {
        if (isDoraClientVisible) {
            // This is used to expand the popover to full width when Dora client is visible
            const popoverElement = document.querySelector(".awsui_container-body-size-large_xjuzf_1yqsn_790") as HTMLElement;
            // Changing the width of the popover to 500px
            if (popoverElement) {
                //unset the inline-size and max-inline-size to allow the popover to expand to full width of 500px
                popoverElement.style.maxInlineSize = 'unset';
                popoverElement.style.inlineSize = 'unset';
                popoverElement.style.overflow = "visible";
                return;
            }
            else { 
                //Changing the width of the DoraClient to match the popover width
                const authWrapperElementAttribute = '[data-testid="small-client-auth-wrapper"]';
                const spinnerWrapperElementAttribute = '[data-testid="canvas-spinner"]';
                const rootCanvasContainerId = "canvas-container";
                const innerCanvasContainerClass = "canvas-container";
                const mainCanvasId = "dora_canvas";
                const secondCanvasClass = "dora_canvas";
                const observerLowerCanvasForLargeScreenClass = "upper-canvas";
                const MUIStack_OverlayId = "canvas-overlay";
        
                // MutationObserver to watch for the disappearance of the auth and spinner wrappers
                const observer = new MutationObserver(() => {
                    const authWrapperElement = document.querySelector(authWrapperElementAttribute) as HTMLElement;
                    const observerLowerCanvasForLargeScreen = document.getElementsByClassName(observerLowerCanvasForLargeScreenClass)[0] as HTMLElement;

                    // Initial override of the CSS for the small client
                    if (authWrapperElement) {
                        authWrapperElement.style.width = "450px";
                    }

                    if (!authWrapperElement) {
                        // when the auth wrapper is removed, we can override the CSS for the small client
                        overrideSmallClientCSS(true);
                        const spinnerWrapperElement = document.querySelector(spinnerWrapperElementAttribute);
                        if (!spinnerWrapperElement) {
                            // when the auth and spinner wrappers are removed, we can override the CSS for the small client
                            overrideSmallClientCSS(true);
                        }
                    }

                    // We Observe the lower canvas for large screen and we will look for the height change
                    // if the height changes, we will fetch the width of the observerLowerCanvasForLargeScreen and set the width of the small client to match the width of the observerLowerCanvasForLargeScreen
                    if (observerLowerCanvasForLargeScreen) {
                        const observerLowerCanvasForLargeScreenWidth = observerLowerCanvasForLargeScreen.clientWidth;
                        if (observerLowerCanvasForLargeScreenWidth !== 500) { 
                                overrideSmallClientCSS(false, `${observerLowerCanvasForLargeScreenWidth}px`);
                        }
                    }

                    /**
                     * Function to override the CSS for the small client to match the popover width or to revert the changes
                     * @param removeWidth boolean to remove the width or not
                     * @param width string to set the width of the small client, used along with removeWidth = false
                     */
                    function overrideSmallClientCSS(removeWidth: boolean, width?: string) {
                        if (removeWidth) { 
                            const rootCanvasContainer = document.getElementById(rootCanvasContainerId);
                            if (rootCanvasContainer) {
                                rootCanvasContainer.style.width = "450px";
                            }

                            const innerCanvasContainer = document.getElementsByClassName(innerCanvasContainerClass)[0] as HTMLElement;
                            if (innerCanvasContainer) {
                                innerCanvasContainer.style.removeProperty("width");
                            }

                            const mainCanvas = document.getElementById(mainCanvasId) as HTMLElement;
                            if (mainCanvas) {
                                mainCanvas.style.removeProperty("width");
                            }

                            const secondCanvas = document.getElementsByClassName(secondCanvasClass)[0] as HTMLElement;
                            if (secondCanvas) {
                                secondCanvas.style.removeProperty("width");
                            }

                            const MUIStack_Overlay = document.getElementById(MUIStack_OverlayId) as HTMLElement;
                            if (MUIStack_Overlay) {
                                MUIStack_Overlay.style.width = "450px";
                            }
                        }
                        else if (!removeWidth && width) {
                            // revert the above changes
                            const rootCanvasContainer = document.getElementById(rootCanvasContainerId);
                            if (rootCanvasContainer) {
                                rootCanvasContainer.style.width = width;
                            }

                            const innerCanvasContainer = document.getElementsByClassName(innerCanvasContainerClass)[0] as HTMLElement;
                            if (innerCanvasContainer) {
                                innerCanvasContainer.style.width = width;
                            }

                            const mainCanvas = document.getElementById(mainCanvasId) as HTMLElement;
                            if (mainCanvas) {
                                mainCanvas.style.width = width;
                            }

                            const secondCanvas = document.getElementsByClassName(secondCanvasClass)[0] as HTMLElement;
                            if (secondCanvas) {
                                secondCanvas.style.width = width;
                            }
                            // we can skip the MUIStack_Overlay as it is not required
                        }
                    }
                });
        
                // Start observing the target node for configured mutations
                const targetNode = document.body; 
                observer.observe(targetNode, {
                    childList: true,
                    subtree: true,  
                });
                return () => {
                    observer.disconnect();
                };
            }
        }
    }, [isDoraClientVisible]);

    const DoraClients = () => { 
        return (
            <>
                <DoraSmallClient
                    key={deviceId}
                    auth={doraAuthConfig}
                    user={user}
                    targetDevice={deviceId}
                    i18n={i18n as any}
                    language={i18n.language}
                    darkMode={darkMode}
                />
            </>
        );
    };

    return {
        isDoraClientVisible,
        deviceId,
        isNonProd,
        setIsDoraClientVisible,
        setDeviceId,
        DoraClients
    };
};
